let Prismic = require("prismic-javascript");
const PrismicDOM = require("prismic-dom");
Prismic = Prismic.default;


$.ajax({
    url: '/api/prismic_api_endpoint',
    method: 'GET'
})
.done(function(response){

    const apiEndpoint = response;

    let lastVisit = localStorage.getItem("last-visit");
    //console.log('last Visit:' + lastVisit);

      Prismic.getApi(apiEndpoint)
      .then(function(api) {
        return api.query("", {
          orderings: "[document.first_publication_date desc]",
          pageSize: 6,
          page: 1,
        });
      })
      .then(
        function(response) {

          if(!response.results.length)
          {
            console.log("No results")
            document.querySelector("#updates-ico").style.visibility = "hidden";

            return;
          }
          //console.log(response.results[0].data.title);

          let lastPost = new Date(response.results[0].first_publication_date)/1000;
          if (parseInt(lastPost) <= parseInt(lastVisit)) {
            $("#updates-ico").css('visibility',"hidden");
          }else{
              console.log(parseInt(lastPost),parseInt(lastVisit) )
            $("#updates-ico").css('visibility',"visible");
            return 0;
          }
        },
        function(err) {
          console.log("Something went wrong: ", err);
        }
      );


});


